import React, { ReactElement } from 'react';
import { TextField, useStore, DateField } from 'react-admin';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import JSONFormShowButton from '../common/JSONForm/JSONFormShowButton';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import CopyTemplatesButton from '../common/CopyTemplatesButton';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import DisciplineTestField from '../common/DisciplineTestField';
import { getSideFiltersKey } from '../common/helpers/storeKeys';
import BulkActionButtons from './components/BulkActionButtons';
import CustomBooleanField from '../common/CustomBooleanField';
import TestSidebarFilters from './TestSidebarFilters';
import DownloadButton from '../common/DownloadButton';
import ListWithTitle from '../common/ListWithTitle';
import TestTypeField from '../common/TestTypeField';
import PreviewButton from '../common/PreviewButton';
import ColumnActions from '../common/ColumnActions';
import { templateType } from '../common/constants';
import ListActions from '../common/ListActions';
import VendorField from '../common/VendorField';
import { RESOURCE_TEST } from '../constants';
import TestEdit from './TestEdit';
import TestShow from './TestShow';

const TestList: React.FC = (): ReactElement => {
  const sideFilter = getSideFiltersKey(RESOURCE_TEST);
  const [showSideFilter] = useStore(sideFilter, false);
  const defaultFilter = useDefaultFilter('division');

  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      sort={{ field: 'id', order: 'ASC' }}
      actions={
        <ListActions
          sideFilter={sideFilter}
          defaultFilter={defaultFilter}
          actions={
            <CopyTemplatesButton
              templateType={templateType.itr}
              resource={RESOURCE_TEST}
              lambdaMode
            />
          }
        />
      }
      aside={
        showSideFilter ? (
          <TestSidebarFilters defaultFilter={defaultFilter} />
        ) : null
      }
    >
      <DatagridConfigurableRbac
        ShowComponent={<TestShow withoutActions />}
        EditComponent={<TestEdit syncWithLocation={false} />}
        bulkActionButtons={<BulkActionButtons />}
        defaultVisibleColumns={[
          'id',
          'testFormNo',
          'testName',
          'disciplineTestId',
          'testTypeId',
          'testResp',
          'isPreservation',
          'duration',
          'class',
        ]}
      >
        <TextField source="id" label="ID" />
        <TextField source="testFormNo" label="Test Form No" />
        <TextField source="testName" label="Test Name" />
        <DisciplineTestField />
        <TestTypeField />
        <TextField
          source="testResp"
          label="Test Responsible"
          sortable={false}
        />
        <TextField
          source="testDetail.attachmentRevision.revision"
          label="Revision"
          sortable={false}
        />
        <DateField
          source="testDetail.attachmentRevision.revisionDate"
          label="Revision Date"
          sortable={false}
        />
        <VendorField textSource="testDetail.vendor.vendor" sortable={false} />
        <CustomBooleanField source="multiTag" label="Multi Tag" />
        <CustomBooleanField source="isPreservation" label="Preservation" />
        <CustomBooleanField source="batch" label="Batch" />
        <TextField source="duration" label="Duration" />
        <TextField source="class" label="Class" />
        <TextField source="externalFormId" label="External Form ID" />
        {renderMetaDataFields()}
        <ColumnActions label="Actions">
          <DownloadButton />
          <JSONFormShowButton title="Test Form" />
          <PreviewButton />
        </ColumnActions>
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default TestList;
