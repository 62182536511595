import React, { useEffect, useState, ReactElement } from 'react';
import {
  RankedTester,
  rankWith,
  ControlProps,
  isBooleanControl,
  optionIs,
  and,
  showAsRequired,
} from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Unwrapped } from '@jsonforms/material-renderers';
import { useFormContext } from 'react-hook-form';
import { Grid, FormLabel } from '@mui/material';
import merge from 'lodash/merge';
import StepAction from './StepAction';

const { MaterialBooleanControl } = Unwrapped;

export const CheckboxControl: React.FC<ControlProps> = (
  props
): ReactElement => {
  const formContext = useFormContext();
  const formData = formContext?.watch('formData');
  const [stepAction, setStepAction] = useState({});
  const isValid = props.errors?.length === 0;
  const appliedUiSchemaOptions = merge(
    {},
    props.config,
    props.uischema?.options
  );

  useEffect(() => {
    if (formData?.stepActions?.[props.path]) {
      setStepAction(formData.stepActions[props.path]);
    }
  }, [formData, setStepAction, props]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <FormLabel
          error={!isValid}
          component="legend"
          required={showAsRequired(
            props.required,
            appliedUiSchemaOptions.hideRequiredAsterisk
          )}
        >
          {props.label}
        </FormLabel>
        <MaterialBooleanControl
          {...props}
          label={props.schema?.['label'] || props.label}
        />
        {stepAction && <StepAction data={stepAction} />}
      </Grid>
    </Grid>
  );
};

export const isCheckboxControl = and(
  isBooleanControl,
  optionIs('format', 'checkbox')
);

export const CheckboxControlTester: RankedTester = rankWith(
  21,
  isCheckboxControl
);

export default withJsonFormsControlProps(CheckboxControl);
