import React, { Fragment, ReactElement } from 'react';
import { BooleanInput } from 'react-admin';
import Box from '@mui/material/Box';
import AssignedWithCreateInput from '../common/Assigned/AssignedWithCreateInput';
import { useDictionaryLabelsWithResource } from '../../utils/UtilityFunctions';
import WithFieldCheckAccess, { Actions } from '../common/WithFieldCheckAccess';
import PreservationSeriesInput from '../common/PreservationSeriesInput';
import { ContactCategories } from '../common/contactInput/constants';
import PreservationItemInput from '../common/PreservationItemInput';
import ContactInput from '../common/contactInput/ContactInput';
import CustomNumberInput from '../common/CustomNumberInput';
import CompletedInput from './components/CompletedInput';
import useCanAccess from '../common/hooks/useCanAccess';
import CustomDateInput from '../common/CustomDateInput';
import { getValidateRequiredFunc } from './validators';
import ComponentInput from '../common/ComponentInput';
import SignatureInput from '../common/SignatureInput';
import LocationInput from '../common/LocationInput';
import commonStyles from '../common/commonStyles';
import TestInput from '../common/TestInput';
import TextInput from '../common/TextInput';

const PreservationActivityForm: React.FC = (): ReactElement => {
  const { labels } = useDictionaryLabelsWithResource();
  const canAccess = useCanAccess();

  return (
    <Fragment>
      <Box sx={commonStyles.flexBox}>
        <PreservationItemInput
          sx={commonStyles.flexBoxItem}
          label={labels['preservationItemId']}
          disabled
        />
        <PreservationSeriesInput
          sx={commonStyles.flexBoxItem}
          label={labels['preservationSeriesId']}
          disabled
        />
        <TestInput
          sx={commonStyles.flexBoxItem}
          label={labels['testId']}
          name="testFormNo_inc"
          optionText="testFormNo"
          disabled
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <ComponentInput sx={commonStyles.flexBoxItem} disabled />
        <LocationInput
          sx={commonStyles.flexBoxItem}
          label={labels['locationId']}
          disabled
        />
        <WithFieldCheckAccess
          action={Actions.EDIT}
          hasResourceEditAccess={false}
        >
          <BooleanInput
            source="isClosed"
            label={labels['isClosed'] || 'Closed'}
            defaultValue={false}
            sx={commonStyles.flexBoxItem}
          />
        </WithFieldCheckAccess>
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="sequence"
          label={labels['sequence'] || 'Sequence'}
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <CustomNumberInput
          source="estimatedManHours"
          label={labels['estimatedManHours'] || 'Estimated Man Hours'}
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <CustomDateInput
          source="onSiteDate"
          label={labels['onSiteDate'] || 'On Site Date'}
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <WithFieldCheckAccess
          action={Actions.EDIT}
          hasResourceEditAccess={canAccess.edit}
        >
          <BooleanInput
            source="isMobile"
            label={labels['isMobile'] || 'Mobile'}
            defaultValue={false}
            sx={commonStyles.flexBoxItem}
          />
        </WithFieldCheckAccess>
      </Box>
      <Box sx={commonStyles.flexBox}>
        <WithFieldCheckAccess
          action={Actions.EDIT}
          hasResourceEditAccess={canAccess.edit}
        >
          <AssignedWithCreateInput
            sx={commonStyles.flexBoxItem}
            category={ContactCategories.preservationEmpWorkGrp}
            label={labels['assigned'] || 'Responsible'}
          />
          <CustomNumberInput
            source="actualManHours"
            label={labels['actualManHours'] || 'Actual Man Hours'}
            sx={commonStyles.flexBoxItem}
          />
          <CustomNumberInput
            source="adminManHours"
            label={labels['adminManHours'] || 'Admin Man Hours'}
            sx={commonStyles.flexBoxItem}
          />
        </WithFieldCheckAccess>
      </Box>
      <Box sx={commonStyles.flexBox}>
        <WithFieldCheckAccess
          action={Actions.EDIT}
          hasResourceEditAccess={canAccess.edit}
        >
          <SignatureInput
            source="inspector"
            label={labels['inspector'] || 'Inspector'}
            sx={[commonStyles.flexBoxItem, commonStyles.bottomMargin]}
          >
            <ContactInput
              source="inspector"
              label={labels['inspector'] || 'Inspector'}
              sx={commonStyles.flexBoxItem}
              category={ContactCategories.preservationEmpWorkGrp}
            />
          </SignatureInput>
          <CustomDateInput
            source="signOffDate"
            label={labels['signOffDate'] || 'Sign Off Date'}
            sx={commonStyles.flexBoxItem}
          />
          <CompletedInput
            source="completed"
            label={labels['completed'] || 'Completed'}
            sx={commonStyles.flexBoxItem}
          />
        </WithFieldCheckAccess>
      </Box>
      <Box sx={commonStyles.flexBox}>
        <WithFieldCheckAccess
          action={Actions.EDIT}
          hasResourceEditAccess={canAccess.edit}
        >
          <SignatureInput
            source="verifiedBy"
            label={labels['verifiedBy'] || 'Verified By'}
            sx={[commonStyles.flexBoxItem, commonStyles.bottomMargin]}
          >
            <ContactInput
              source="verifiedBy"
              label={labels['verifiedBy'] || 'Verified By'}
              sx={commonStyles.flexBoxItem}
              validate={[
                getValidateRequiredFunc('verifiedDate', 'Verified Date'),
              ]}
              category={ContactCategories.preservationEmpWorkGrp}
            />
          </SignatureInput>
          <CustomDateInput
            source="verifiedDate"
            label={labels['verifiedDate'] || 'Verified Date'}
            sx={commonStyles.flexBoxItem}
            validate={[getValidateRequiredFunc('verifiedBy', 'Verified By')]}
          />
        </WithFieldCheckAccess>
      </Box>
      <Box sx={commonStyles.flexBox}>
        <CustomDateInput
          source="startDate"
          label={labels['startDate'] || 'Start Date'}
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <CustomDateInput
          source="endDate"
          label={labels['endDate'] || 'End Date'}
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <CustomNumberInput
          source="frequency"
          label={labels['frequency'] || 'Frequency'}
          sx={commonStyles.flexBoxItem}
          disabled
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <CustomDateInput
          source="dueDate"
          label={labels['dueDate'] || 'Due Date'}
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <CustomDateInput
          source="lateDate"
          label={labels['lateDate'] || 'Late Date'}
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <CustomNumberInput
          source="plusDate"
          label={labels['plusDate'] || 'Plus Date'}
          sx={commonStyles.flexBoxItem}
          disabled
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="printedBy"
          label={labels['printedBy'] || 'Printed By'}
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <CustomDateInput
          source="printDate"
          label={labels['printDate'] || 'Printed Date'}
          sx={commonStyles.flexBoxItem}
          disabled
        />
      </Box>
      <Box sx={commonStyles.createBoxHalfWidth}>
        <TextInput
          source="storagePreservation"
          label={labels['storagePreservation'] || 'Storage Preservation'}
          fullWidth
          multiline
          disabled
        />
      </Box>
    </Fragment>
  );
};

export default PreservationActivityForm;
