import {
  getContext,
  updateContext,
  UserContext,
} from '../../../provider/userContext';
import { api } from '../../../provider/api';
import { useCallback } from 'react';

const useRefreshCompanyContext = () => {
  return useCallback(async () => {
    const myDetails = await api.user.getMyDetails();

    if (myDetails?.data?.companies) {
      const ctx: UserContext = getContext();
      ctx.companies = myDetails.data.companies;

      updateContext(ctx);
    }
  }, []);
};

export default useRefreshCompanyContext;
