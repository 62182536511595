import React, { ReactElement, useMemo } from 'react';
import { FormHelperText } from '@mui/material';
import grey from '@mui/material/colors/grey';
import moment from 'moment/moment';
import { getLocaleDateFormat } from '../../../utils/UtilityFunctions';
import { validateDate } from '../helpers/dateFilter';
import { getLocaleFormatDate } from './helpers';

interface StepActionProps {
  data: {
    updatedBy?: string;
    updatedAt?: string;
    clearedBy?: string;
    clearedAt?: string;
  };
}
export const StepAction: React.FC<StepActionProps> = ({
  data: { updatedBy, updatedAt, clearedBy, clearedAt },
}): ReactElement => {
  const localeDateFormat = useMemo(getLocaleDateFormat, []);
  const date = updatedAt || clearedAt ? moment(updatedAt || clearedAt) : null;
  const localDate =
    date && validateDate(date)
      ? getLocaleFormatDate(date, localeDateFormat)
      : '';

  return (
    <FormHelperText
      sx={{ marginBottom: '15px', marginTop: '-5px', color: grey[600] }}
    >
      {updatedBy && `Updated: ${updatedBy || ''} ${localDate}`}
      {clearedBy && `Cleared: ${clearedBy || ''} ${localDate}`}
    </FormHelperText>
  );
};

export default StepAction;
