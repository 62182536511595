import React, {
  useCallback,
  useState,
  useEffect,
  Fragment,
  ReactElement,
  useMemo,
} from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { clearLocalStorageListParamsFilter } from '../../utils/UtilityFunctions';
import {
  getContext,
  UserContext,
  updateContext,
} from '../../provider/userContext';
import { Project } from './types';
import commonStyles from '../common/commonStyles';
import ProjectSelection from './projectSelection/ProjectSelection';
import { RESOURCE_PROJECT } from '../constants';
import { initNotificationContext } from '../../provider/notificationContext';
import useDataProvider from '../../provider/dataProvider/useDataProvider';
import { useRedirect } from 'react-admin';

const ProjectSwitch: React.FC = (): ReactElement => {
  const queryClient = useQueryClient();
  const [projects, setProjects] = useState([]);
  const [totalProjects, setTotalProjects] = useState(0);
  const ctx: UserContext = getContext();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const ctxProjectIds = useMemo(() => {
    return ctx
      ? Object.keys(ctx?.companies?.[ctx.company]?.projects || {})
      : ['0'];
  }, [ctx]);

  useEffect(() => {
    const getProjects = async () => {
      const data = await dataProvider.getList(RESOURCE_PROJECT, {
        pagination: {
          page: 1,
          perPage: 100,
        },
        sort: {
          field: 'project',
          order: 'ASC',
        },
        filter: {
          id: ctxProjectIds,
          isActive: true,
          divisionId: ctx?.companies?.[ctx.company]?.defaultDivisionId,
        },
      });

      setProjects(data.data);
      setTotalProjects(data.total);
    };

    if (ctx?.company) {
      getProjects().then();
    }
  }, [
    setProjects,
    setTotalProjects,
    ctx?.companies,
    ctx?.company,
    ctxProjectIds,
    dataProvider,
  ]);

  const [currentProject, setCurrentProject] = useState<Project | null>(null);
  const [openProjectSelection, setOpenProjectSelection] = useState(false);

  const handleSwitchChange = useCallback(
    (e, value: Project) => {
      updateContext({ projectId: Number(value.id) });
      queryClient.getQueryCache().clear();
      clearLocalStorageListParamsFilter();
      redirect('/');
    },
    [queryClient, redirect]
  );

  const handleClose = (
    event?: object,
    reason?: 'backdropClick' | 'escapeKeyDown'
  ) => {
    if (reason === 'backdropClick') return;
    initNotificationContext(ctx?.ablyToken, ctx?.company, ctx?.id);
    setOpenProjectSelection(false);
  };

  useEffect(() => {
    if (ctx?.projectId && projects) {
      projects.map((item) => {
        if (item.id === ctx?.projectId) setCurrentProject(item);
      });
    }
  }, [ctx?.projectId, projects]);

  useEffect(() => {
    if (!ctx?.projectId) setOpenProjectSelection(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      {!ctx?.apiKey && (
        <Fragment>
          {projects?.length && currentProject && (
            <Autocomplete
              value={currentProject}
              onChange={handleSwitchChange}
              options={projects}
              getOptionLabel={(option) => option.project}
              isOptionEqualToValue={(option, value) =>
                option.project === value.project
              }
              renderInput={(params) => <TextField {...params} />}
              disableClearable
              sx={{
                ...commonStyles.flexBoxItem,
                '& button, & input': { color: 'white' },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white !important',
                },
              }}
            />
          )}
          <ProjectSelection
            title={`Select Project${
              totalProjects ? ` (${totalProjects} Total)` : ''
            }`}
            open={openProjectSelection}
            handleClose={handleClose}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default ProjectSwitch;
