import {
  CreateParams,
  GetListParams,
  GetManyParams,
  GetOneParams,
  UpdateParams,
} from 'react-admin';
import axios from 'axios';
import {
  DynamoUserCompany,
  DynamoUserCompanyWithArray,
  getContext,
  UserContext,
} from '../../userContext';
import {
  BOOK_GEN_API,
  CRUD_API_URL,
  getRequestConfig,
  UI_URL,
} from '../../api';
import { getDefaultDataProvider } from './defaultDataProvider';

const defaultProvider = getDefaultDataProvider();

const addCurrentProjectToCompanies = (
  companyList: Record<string, DynamoUserCompany>
): Record<string, DynamoUserCompany> => {
  const ctx: UserContext = getContext();

  return Object.keys(companyList).reduce((acc, companyKey) => {
    const company = companyList[companyKey];
    acc[companyKey] = {
      ...company,
      currentProject: {
        roles: company.projects[ctx.projectId]?.roles || [],
        groups: company.projects[ctx.projectId]?.groups || [],
        userGroups: company.projects[ctx.projectId]?.userGroups || [],
      },
    };

    return acc;
  }, {});
};

const transformProjectsToArray = (
  companyList: Record<string, DynamoUserCompany>
): Record<string, DynamoUserCompanyWithArray> => {
  return Object.keys(companyList).reduce((acc, companyKey) => {
    const company = companyList[companyKey];
    acc[companyKey] = {
      ...company,
      projects: Object.keys(company.projects).reduce((list, prKey) => {
        list.push({
          roles: company.projects[prKey].roles || [],
          groups: company.projects[prKey].groups || [],
          userGroups: company.projects[prKey].userGroups || [],
          effectivePermissions:
            company.projects[prKey].effectivePermissions || [],
          id: prKey,
        });

        return list;
      }, []),
    };

    return acc;
  }, {});
};

const sendMicrosoftSSOInvitation = async (body) => {
  try {
    return await axios.post(
      `${BOOK_GEN_API}/api/user/user-invitation`,
      body,
      getRequestConfig()
    );
  } catch (e) {
    throw e;
  }
};

const resetPassword = async (resource, body) => {
  try {
    return await axios.post(
      `${CRUD_API_URL}/${resource}/reset-password`,
      body,
      getRequestConfig()
    );
  } catch (e) {
    throw e;
  }
};

const userDataProvider = {
  create: async (
    resource: string,
    params: CreateParams
  ): Promise<{ data: UserContext }> => {
    const result = await defaultProvider.create(resource, params);

    return {
      data: {
        ...result.data,
        companies: transformProjectsToArray(
          addCurrentProjectToCompanies(result.data.companies || {})
        ),
      },
    };
  },
  update: async (resource: string, params: UpdateParams) => {
    const result = await defaultProvider.update(resource, params);

    return {
      data: {
        ...result.data,
        companies: transformProjectsToArray(
          addCurrentProjectToCompanies(result.data.companies || {})
        ),
      },
    };
  },
  getOne: async (resource: string, params: GetOneParams) => {
    const result = await defaultProvider.getOne(resource, params);

    return {
      data: {
        ...result.data,
        companies: transformProjectsToArray(
          addCurrentProjectToCompanies(result.data.companies || {})
        ),
      },
    };
  },
  getList: async (resource: string, params: GetListParams) => {
    const result = await defaultProvider.getList(resource, params);
    return {
      total: result.total,
      data: result.data.map((item) => {
        return {
          ...item,
          companies: transformProjectsToArray(
            addCurrentProjectToCompanies(item.companies)
          ),
        };
      }),
    };
  },
  getMany: async (resource: string, params: GetManyParams) => {
    const result = await defaultProvider.getMany(resource, params);
    return {
      data: result.data.map((item) => {
        return {
          ...item,
          companies: transformProjectsToArray(
            addCurrentProjectToCompanies(item.companies)
          ),
        };
      }),
    };
  },
  sendMicrosoftSSOInvitation: async (
    _resource: string,
    params: {
      user: object;
    }
  ) => {
    const { user } = params;
    const body = {
      invitedUserEmailAddress: user?.['id'],
      invitedUserDisplayName: `${user?.['firstName']} ${user?.['lastName']}`,
      inviteRedirectUrl: UI_URL,
      sendInvitationMessage: true,
    };
    await sendMicrosoftSSOInvitation([body]);
  },
  resetUserPassword: async (
    resource: string,
    params: {
      user: object;
    }
  ) => {
    await resetPassword(resource, params);
  },
};

export default userDataProvider;
