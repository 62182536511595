import { canAccess } from '@react-admin/ra-rbac';
import { actionPermissions } from '../common/constants';
import {
  RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY,
  RESOURCE_SMART_TAG_ITR,
  RESOURCE_SMART_TAG_ITR_REVIEW,
} from '../constants';
import checkSecurityLevel from '../common/helpers/checkSecurityLevel';

export const processMenuItems = (menuItems, permissions) => {
  const resultMenuItems = [];

  menuItems
    .filter((menuItem) => checkSecurityLevel(menuItem.leastSecurityLevel))
    .map((menuItem) => {
      switch (true) {
        case !menuItem.hasOwnProperty('items') &&
          !menuItem.hasOwnProperty('resource'):
          resultMenuItems.push(menuItem);
          break;
        case menuItem.hasOwnProperty('resource') &&
          canAccess({
            permissions,
            action: actionPermissions.list,
            resource: menuItem.resource,
          }):
          resultMenuItems.push(menuItem);
          break;
        case menuItem.hasOwnProperty('items'):
          resultMenuItems.push({
            ...menuItem,
            items: processMenuItems(menuItem.items, permissions),
          });
          break;
      }
    });

  return resultMenuItems;
};

export const getFilteredMenuItems = (menuItems, currentProjectSettings) => {
  const filteredMenuItems = [];

  menuItems.map((menuItem) => {
    switch (true) {
      case !menuItem.hasOwnProperty('items'):
        filteredMenuItems.push(menuItem);
        break;
      case menuItem.hasOwnProperty('items') && !!menuItem.items.length:
        const filteredSubItems = getFilteredMenuItems(
          menuItem.items,
          currentProjectSettings
        );

        if (!!filteredSubItems.length) {
          const availableSubItems = getAvailableSubItems(
            filteredSubItems,
            currentProjectSettings
          );

          filteredMenuItems.push({
            ...menuItem,
            items: availableSubItems,
          });
        }
        break;
    }
  });

  return filteredMenuItems;
};

const getAvailableSubItems = (subItems, currentProjectSettings) => {
  return subItems.filter((subItem) => {
    if (
      (!currentProjectSettings.isPresMobile &&
        subItem.resource === RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY) ||
      (!currentProjectSettings.isItrMobile &&
        (subItem.resource === RESOURCE_SMART_TAG_ITR ||
          subItem.resource === RESOURCE_SMART_TAG_ITR_REVIEW))
    ) {
      return false;
    }
    return true;
  });
};

export const getParentMenuItemList = (menuItems, path) => {
  const parentMenuItems = [];

  const getParentMenuItem = (parentItems) => {
    const isIncludePath = (items) => {
      for (const item of items) {
        if (item.resource === path) {
          return true;
        }

        if (item.items?.length > 0) {
          if (isIncludePath(item.items)) {
            return true;
          }
        }
      }

      return false;
    };

    for (const item of parentItems) {
      if (item.items?.length > 0) {
        if (isIncludePath(item.items)) {
          parentMenuItems.push(item.name);
          getParentMenuItem(item.items);
        }
      }
    }

    return parentMenuItems;
  };

  return getParentMenuItem(menuItems);
};
