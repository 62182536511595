import React, { Fragment, ReactElement } from 'react';
import {
  AppBar as BaseAppBar,
  RefreshIconButton,
  AppBarProps,
  TitlePortal,
} from 'react-admin';
import Logo from './components/common/Logo';
import ProjectSwitch from './components/project/ProjectSwitch';
import UserMenu from './components/userMenu/UserMenu';
import { ReactComponent as RefreshIcon } from './assets/icons/functionalIcons/Refresh.svg';
import NotificationBase from './components/notification/NotificationBase';
import { getContext, UserContext } from './provider/userContext';
import { ContactSupportModal } from './components/support/ContactSupportModal';
import SubscriptionInfo from './components/subscriptionInfo/SubscriptionInfo';
import ModalApproval from './components/modalApproval/ModalApproval';
import { RecordingInProgress } from './components/RecordingInProgress';
import { useSupportModal } from './components/common/hooks/useSupportModal';

const AppBar: React.FC<AppBarProps> = (props): ReactElement => {
  const ctx: UserContext = getContext();
  const {
    onClose,
    onOpen,
    onStopRecordingAndOpen,
    onStartRecording,
    recordingInProgress,
    withRecording,
    lastRecordTimeRange,
    recordLink,
    supportModalOpen,
  } = useSupportModal();

  return (
    <BaseAppBar
      {...props}
      sx={{ borderRadius: '4px' }}
      toolbar={
        <Fragment>
          <ProjectSwitch />
          {ctx?.id ? <ModalApproval /> : null}
          {ctx?.id && ctx?.ablyToken && ctx?.projectId ? (
            <NotificationBase />
          ) : null}
          <RefreshIconButton icon={<RefreshIcon />} />
          {recordingInProgress && (
            <RecordingInProgress onClick={onStopRecordingAndOpen} />
          )}
        </Fragment>
      }
      userMenu={
        <UserMenu
          recordingInProgress={recordingInProgress}
          onOpenSupportModal={onOpen}
          onStartRecording={onStartRecording}
          onStopRecording={onStopRecordingAndOpen}
        />
      }
    >
      <Logo />
      <TitlePortal align="center" />
      <ContactSupportModal
        open={supportModalOpen}
        onClose={onClose}
        withRecording={withRecording}
        lastRecordTimeRange={lastRecordTimeRange}
        recordLink={recordLink}
      />
      <SubscriptionInfo />
    </BaseAppBar>
  );
};

export default AppBar;
