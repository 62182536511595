import React, { ReactElement, Fragment, useCallback, useState } from 'react';
import { useStore, useResourceContext } from 'react-admin';
import useCheckCurrentProjectResourceAccessSettings from '../common/hooks/useCheckCurrentProjectResourceAccessSettings';
import SmartTagITRSidebarFilters from './components/SmartTagITRSidebarFilters';
import SmartTagITRListDatagrid from './components/SmartTagITRListDatagrid';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import FilterMyItemsButton from '../common/FilterMyItemsButton';
import { getSideFiltersKey } from '../common/helpers/storeKeys';
import { RESOURCE_SMART_TAG_ITR } from '../constants';
import ListWithTitle from '../common/ListWithTitle';
import LegendButton from '../common/LegendButton';
import ListActions from '../common/ListActions';
import SmartTagITREdit from './SmartTagITREdit';
import { RED } from '../../theme/colors';

const legends = [
  {
    label: 'Rejected ITR',
    color: RED,
  },
];

const SmartTagITRList: React.FC = (): ReactElement => {
  useCheckCurrentProjectResourceAccessSettings();

  const resource: string = useResourceContext();
  const sideFilter = getSideFiltersKey(resource);
  const [showSideFilter] = useStore(sideFilter, false);
  const [selectedRecordId, setSelectedRecordId] = useState<number | null>(null);
  const [, setExpanded] = useStore(`${resource}.datagrid.expanded`, []);
  const defaultFilter = useDefaultFilter('project', {
    isDeleted: false,
    isMobile: true,
    mobileTestStatus: ['NONE', 'REJECTED'],
  });

  const onCancel = useCallback(() => setExpanded([]), [setExpanded]);

  return (
    <ListWithTitle
      resource={RESOURCE_SMART_TAG_ITR}
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={
        <ListActions
          sideFilter={sideFilter}
          defaultFilter={defaultFilter}
          showAction={{
            import: false,
            export: false,
            effective: false,
            create: false,
            select: true,
          }}
          actions={
            <>
              <FilterMyItemsButton defaultFilter={defaultFilter} />
              <LegendButton legends={legends} />
            </>
          }
        />
      }
      sort={{ field: 'id', order: 'ASC' }}
      aside={
        <Fragment>
          {showSideFilter && (
            <SmartTagITRSidebarFilters defaultFilter={defaultFilter} />
          )}
          {selectedRecordId && (
            <SmartTagITREdit
              syncWithLocation={false}
              id={selectedRecordId}
              resource={RESOURCE_SMART_TAG_ITR}
              onCancel={onCancel}
            />
          )}
        </Fragment>
      }
    >
      <SmartTagITRListDatagrid setSelectedRecordId={setSelectedRecordId} />
    </ListWithTitle>
  );
};

export default SmartTagITRList;
