import { useGetList } from 'react-admin';
import { RESOURCE_PROJECT } from '../../constants';
import { getProjectItem } from '../helpers';

const useOptionList = (params) => {
  const { data: projects = [] } = useGetList(RESOURCE_PROJECT, params);
  const activeProjects =
    projects
      .filter((project) => project.isActive)
      .sort((project1, project2) =>
        project1.project.localeCompare(project2.project)
      ) ?? [];
  const inActiveProjects =
    projects
      .filter((project) => !project.isActive)
      .sort((project1, project2) =>
        project1.project.localeCompare(project2.project)
      ) ?? [];

  return [...activeProjects, ...inActiveProjects].map((project) =>
    getProjectItem(project)
  );
};

export default useOptionList;
