import React, { ReactElement } from 'react';
import { useGetOne, useRecordContext } from 'react-admin';
import useRolesSecurityLevelValidator from '../../common/hooks/useRolesSecurityLevelValidator';
import ProjectInput from './ProjectInput';
import RoleInput from './RoleInput';
import GroupInput from './GroupInput';
import UserGroupInput from './UserGroupInput';
import { RESOURCE_PROJECT } from '../../../components/constants';

const ProjectsInputField: React.FC = (): ReactElement => {
  const record = useRecordContext();
  const { data: currentProject } = useGetOne(RESOURCE_PROJECT, {
    id: record?.id,
  });
  const readOnly = currentProject && !currentProject?.isActive;

  const rolesSecurityLevelValidator = useRolesSecurityLevelValidator();

  return (
    <React.Fragment>
      <ProjectInput
        source="id"
        label="Project"
        isRequired
        readOnly={readOnly}
      />
      <GroupInput source="groups" label="Groups *" readOnly={readOnly} />
      <RoleInput
        source="roles"
        label="Roles"
        readOnly={readOnly}
        validate={rolesSecurityLevelValidator}
      />
      <UserGroupInput
        source="userGroups"
        label="User Groups"
        readOnly={readOnly}
      />
    </React.Fragment>
  );
};

export default ProjectsInputField;
