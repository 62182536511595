import React, { ReactElement } from 'react';
import { CustomRoutes, localStorageStore } from 'react-admin';
import { Route } from 'react-router-dom';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import en from 'ra-language-english';
import { Admin as AdminEnterprise } from '@react-admin/ra-enterprise';
import { Resource } from '@react-admin/ra-rbac';
import '@progress/kendo-theme-material/dist/all.css';
import './App.css';
import { lightTheme } from './theme';
import { AppLayout } from './AppLayout';
import { getAuthProvider } from './provider/authProvider/authProvider';
import {
  RESOURCE_ATTACHMENT,
  RESOURCE_PROJECT,
  RESOURCE_MODULE,
  RESOURCE_SYSTEM,
  RESOURCE_SYSTEM_ACTIVITY,
  RESOURCE_DISCIPLINE,
  RESOURCE_DICTIONARY,
  RESOURCE_COMMENT,
  RESOURCE_WORK_PACK,
  RESOURCE_DIVISION,
  RESOURCE_GROUP,
  RESOURCE_STRUCTURE_REGION,
  RESOURCE_LOCATION,
  RESOURCE_PUNCH_CATEGORY,
  RESOURCE_PUNCH_PRIORITY,
  RESOURCE_SUBSYSTEM,
  RESOURCE_AUDIT,
  RESOURCE_STRUCTURE_ASSET_GROUP,
  RESOURCE_STRUCTURE_ASSET,
  RESOURCE_TEST_TYPE,
  RESOURCE_DRAWING_TYPE,
  RESOURCE_SECURITY_ROLE,
  RESOURCE_SECURITY_GROUP,
  RESOURCE_SECURITY_ROLE_PERMISSION,
  RESOURCE_POSITION,
  RESOURCE_DRAWING_DISCIPLINE,
  RESOURCE_ACTIVITY_TYPE,
  RESOURCE_VENDOR,
  RESOURCE_ACTIVITY_PHASE,
  RESOURCE_AUDIT_TABLE_NAME,
  RESOURCE_EQUIPMENT_TYPE,
  RESOURCE_USER,
  RESOURCE_DISCIPLINE_TEST,
  RESOURCE_USER_GROUP,
  RESOURCE_COMPONENT,
  RESOURCE_BOOK,
  RESOURCE_DRAWING,
  RESOURCE_IMPORT,
  RESOURCE_CONTACT,
  CUSTOM_ROUTE_USER_PROFILE,
  RESOURCE_PRESERVATION_ITEM,
  RESOURCE_COMPONENT_TEST,
  RESOURCE_TEST,
  RESOURCE_ATTACHMENT_REVISION,
  RESOURCE_MOC_TYPE,
  RESOURCE_PUNCH,
  RESOURCE_PRESERVATION_SERIES,
  RESOURCE_PRESERVATION_ITEM_ACTIVITY,
  RESOURCE_BOOK_SECTION,
  RESOURCE_CERT_TEMPLATE,
  RESOURCE_MOC,
  RESOURCE_CERT_GATING,
  RESOURCE_SUBSYSTEM_ACTIVITY,
  RESOURCE_DRAWING_PREFIX,
  RESOURCE_CONFIGURATION,
  RESOURCE_ASSIGN_ITR,
  RESOURCE_COMPONENT_ACTIVITY,
  RESOURCE_SMART_TAG_ITR_REVIEW,
  RESOURCE_SMART_TAG_ITR,
  CUSTOM_ROUTE_BOOK_GENERATION,
  RESOURCE_PRINTED_BOOK,
  RESOURCE_QR_SCANNER,
  RESOURCE_PENDING_PUNCH,
  CUSTOM_ROUTE_QR_CODE_GENERATION,
  RESOURCE_REPORT,
  RESOURCE_REPORT_VIEW,
  RESOURCE_ATTACHMENT_TYPE,
  RESOURCE_PUNCH_TEMPLATE,
  RESOURCE_CERT_DETAIL,
  RESOURCE_SMART_CERT_DETAIL,
  RESOURCE_SUBMIT_PUNCH,
  RESOURCE_COMPANY_SUBSCRIPTION,
  RESOURCE_LUCID_DIAGRAM,
  RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY,
  RESOURCE_MATERIAL_TYPE,
  RESOURCE_STORAGE,
  RESOURCE_MATERIAL,
  RESOURCE_MATERIAL_RELEASED,
  RESOURCE_MATERIAL_PURCHASE_ORDER,
} from './components/constants';
import { Dashboard } from './components/dashboard/Dashboard';
import EmptyLogin from './components/EmptyLogin';
import PrintedBooksList from './components/printedBooks/PrintedBooksList';
import QrScannerEdit from './components/qrScanner/QrScannerEdit';
import QrScannerList from './components/qrScanner/QrScannerList';
import QrScannerShow from './components/qrScanner/QrScannerShow';
import ModuleList from './components/module/ModuleList';
import ModuleEdit from './components/module/ModuleEdit';
import ModuleCreate from './components/module/ModuleCreate';
import LocationList from './components/location/LocationList';
import LocationCreate from './components/location/LocationCreate';
import LocationEdit from './components/location/LocationEdit';
import DisciplineList from './components/discipline/DisciplineList';
import DisciplineEdit from './components/discipline/DisciplineEdit';
import DisciplineCreate from './components/discipline/DisciplineCreate';
import DictionaryList from './components/dictionary/DictionaryList';
import DictionaryEdit from './components/dictionary/DictionaryEdit';
import DictionaryCreate from './components/dictionary/DictionaryCreate';
import SystemList from './components/system/SystemList';
import SystemEdit from './components/system/SystemEdit';
import SystemCreate from './components/system/SystemCreate';
import CommentList from './components/comment/CommentList';
import CommentEdit from './components/comment/CommentEdit';
import CommentCreate from './components/comment/CommentCreate';
import CommentShow from './components/comment/CommentShow';
import WorkPackList from './components/workPack/WorkPackList';
import WorkPackEdit from './components/workPack/WorkPackEdit';
import WorkPackCreate from './components/workPack/WorkPackCreate';
import renderWorkPackOption from './components/common/helpers/renderWorkPackOption';
import PunchCategoryList from './components/punchCategory/PunchCategoryList';
import PunchCategoryEdit from './components/punchCategory/PunchCategoryEdit';
import PunchCategoryCreate from './components/punchCategory/PunchCategoryCreate';
import DivisionList from './components/division/DivisionList';
import DivisionEdit from './components/division/DivisionEdit';
import DivisionCreate from './components/division/DivisionCreate';
import renderDivisionOption from './components/common/helpers/renderDivisionOption';
import StructureRegionList from './components/structureRegion/StructureRegionList';
import StructureRegionEdit from './components/structureRegion/StructureRegionEdit';
import StructureRegionCreate from './components/structureRegion/StructureRegionCreate';
import renderRegionOption from './components/common/helpers/renderRegionOption';
import PunchPriorityEdit from './components/punchPriority/PunchPriorityEdit';
import PunchPriorityList from './components/punchPriority/PunchPriorityList';
import PunchPriorityCreate from './components/punchPriority/PunchPriorityCreate';
import SubSystemShow from './components/subsystem/SubSystemShow';
import renderSubSystemOption from './components/common/helpers/renderSubSystemOption';
import SubSystemEdit from './components/subsystem/SubSystemEdit';
import SubSystemList from './components/subsystem/SubSystemList';
import SubSystemCreate from './components/subsystem/SubSystemCreate';
import AuditList from './components/audit/AuditList';
import StructureAssetGroupList from './components/structureAssetGroup/StructureAssetGroupList';
import StructureAssetGroupEdit from './components/structureAssetGroup/StructureAssetGroupEdit';
import StructureAssetGroupCreate from './components/structureAssetGroup/StructureAssetGroupCreate';
import TestTypeList from './components/testType/TestTypeList';
import TestTypeEdit from './components/testType/TestTypeEdit';
import TestTypeCreate from './components/testType/TestTypeCreate';
import StructureAssetList from './components/structureAsset/StructureAssetList';
import StructureAssetEdit from './components/structureAsset/StructureAssetEdit';
import StructureAssetCreate from './components/structureAsset/StructureAssetCreate';
import DrawingTypeList from './components/drawingType/DrawingTypeList';
import DrawingTypeEdit from './components/drawingType/DrawingTypeEdit';
import DrawingTypeCreate from './components/drawingType/DrawingTypeCreate';
import GroupList from './components/group/GroupList';
import GroupEdit from './components/group/GroupEdit';
import GroupCreate from './components/group/GroupCreate';
import SecurityRoleList from './components/securityRole/SecurityRoleList';
import SecurityRoleEdit from './components/securityRole/SecurityRoleEdit';
import SecurityRoleCreate from './components/securityRole/SecurityRoleCreate';
import SecurityRoleShow from './components/securityRole/SecurityRoleShow';
import SecurityGroupList from './components/securityGroup/SecurityGroupList';
import SecurityGroupEdit from './components/securityGroup/SecurityGroupEdit';
import SecurityGroupCreate from './components/securityGroup/SecurityGroupCreate';
import SecurityGroupShow from './components/securityGroup/SecurityGroupShow';
import SecurityRolePermissionList from './components/securityRolePermission/SecurityRolePermissionList';
import SecurityRolePermissionEdit from './components/securityRolePermission/SecurityRolePermissionEdit';
import SecurityRolePermissionCreate from './components/securityRolePermission/SecurityRolePermissionCreate';
import SecurityRolePermissionShow from './components/securityRolePermission/SecurityRolePermissionShow';
import renderAssetOption from './components/common/helpers/renderAssetOption';
import PositionEdit from './components/position/PositionEdit';
import positionList from './components/position/PositionList';
import positionCreate from './components/position/PositionCreate';
import renderDisciplineOption from './components/common/helpers/renderDisciplineOption';
import PositionShow from './components/position/PositionShow';
import ActivityTypeList from './components/activityType/ActivityTypeList';
import ActivityTypeEdit from './components/activityType/ActivityTypeEdit';
import ActivityTypeCreate from './components/activityType/ActivityTypeCreate';
import ActivityTypeShow from './components/activityType/ActivityTypeShow';
import DisciplineShow from './components/discipline/DisciplineShow';
import DictionaryShow from './components/dictionary/DictionaryShow';
import DivisionShow from './components/division/DivisionShow';
import DrawingTypeShow from './components/drawingType/DrawingTypeShow';
import GroupShow from './components/group/GroupShow';
import LocationShow from './components/location/LocationShow';
import renderLocationOption from './components/common/helpers/renderLocationOption';
import ModuleShow from './components/module/ModuleShow';
import PunchCategoryShow from './components/punchCategory/PunchCategoryShow';
import PunchPriorityShow from './components/punchPriority/PunchPriorityShow';
import StructureRegionShow from './components/structureRegion/StructureRegionShow';
import StructureAssetShow from './components/structureAsset/StructureAssetShow';
import StructureAssetGroupShow from './components/structureAssetGroup/StructureAssetGroupShow';
import TestTypeShow from './components/testType/TestTypeShow';
import WorkPackShow from './components/workPack/WorkPackShow';
import SystemShow from './components/system/SystemShow';
import AdminAttachmentList from './components/attachment/admin/AdminAttachmentList';
import DrawingDisciplineList from './components/drawingDiscipline/DrawingDisciplineList';
import DrawingDisciplineEdit from './components/drawingDiscipline/DrawingDisciplineEdit';
import DrawingDisciplineCreate from './components/drawingDiscipline/DrawingDisciplineCreate';
import DrawingDisciplineShow from './components/drawingDiscipline/DrawingDisciplineShow';
import VendorCreate from './components/vendor/VendorCreate';
import VendorEdit from './components/vendor/VendorEdit';
import VendorList from './components/vendor/VendorList';
import VendorShow from './components/vendor/VendorShow';
import ActivityPhaseList from './components/activityPhase/ActivityPhaseList';
import ActivityPhaseEdit from './components/activityPhase/ActivityPhaseEdit';
import ActivityPhaseCreate from './components/activityPhase/ActivityPhaseCreate';
import ActivityPhaseShow from './components/activityPhase/ActivityPhaseShow';
import EquipmentTypeList from './components/equipmentType/EquipmentTypeList';
import EquipmentTypeEdit from './components/equipmentType/EquipmentTypeEdit';
import EquipmentTypeCreate from './components/equipmentType/EquipmentTypeCreate';
import EquipmentTypeShow from './components/equipmentType/EquipmentTypeShow';
import UserProfileEdit from './components/userProfile/UserProfileEdit';
import UserList from './components/user/UserList';
import UserEdit from './components/user/UserEdit';
import UserCreate from './components/user/UserCreate';
import UserShow from './components/user/UserShow';
import DisciplineTestCreate from './components/disciplineTest/DisciplineTestCreate';
import DisciplineTestList from './components/disciplineTest/DisciplineTestList';
import DisciplineTestEdit from './components/disciplineTest/DisciplineTestEdit';
import DisciplineTestShow from './components/disciplineTest/DisciplineTestShow';
import ProjectCreate from './components/project/ProjectCreate';
import ProjectList from './components/project/ProjectList';
import ProjectShow from './components/project/ProjectShow';
import ProjectEdit from './components/project/ProjectEdit';
import renderProjectOption from './components/common/helpers/renderProjectOption';
import renderAssetGroupOption from './components/common/helpers/renderAssetGroupOption';
import UserGroupCreate from './components/userGroup/UserGroupCreate';
import UserGroupEdit from './components/userGroup/UserGroupEdit';
import UserGroupList from './components/userGroup/UserGroupList';
import UserGroupShow from './components/userGroup/UserGroupShow';
import ComponentCreate from './components/component/ComponentCreate';
import ComponentEdit from './components/component/ComponentEdit';
import ComponentList from './components/component/ComponentList';
import ComponentShow from './components/component/ComponentShow';
import BookList from './components/book/BookList';
import BookEdit from './components/book/BookEdit';
import BookShow from './components/book/BookShow';
import BookCreate from './components/book/BookCreate';
import DrawingList from './components/drawing/DrawingList';
import DrawingEdit from './components/drawing/DrawingEdit';
import DrawingShow from './components/drawing/DrawingShow';
import DrawingCreate from './components/drawing/DrawingCreate';
import ImportList from './components/import/ImportList';
import ImportShow from './components/import/ImportShow';
import PreservationItemCreate from './components/preservationItem/PreservationItemCreate';
import PreservationItemEdit from './components/preservationItem/PreservationItemEdit';
import PreservationItemList from './components/preservationItem/PreservationItemList';
import PreservationItemShow from './components/preservationItem/PreservationItemShow';
import ContactCreate from './components/contact/ContactCreate';
import ContactEdit from './components/contact/ContactEdit';
import ContactList from './components/contact/ContactList';
import ContactShow from './components/contact/ContactShow';
import renderComponentOption from './components/common/helpers/renderComponentOption';
import ComponentTestCreate from './components/componentTest/ComponentTestCreate';
import ComponentTestEdit from './components/componentTest/ComponentTestEdit';
import ComponentTestList from './components/componentTest/ComponentTestList';
import ComponentTestShow from './components/componentTest/ComponentTestShow';
import TestCreate from './components/test/TestCreate';
import TestEdit from './components/test/TestEdit';
import TestList from './components/test/TestList';
import TestShow from './components/test/TestShow';
import renderTestOption from './components/common/helpers/renderTestOption';
import AttachmentRevisionList from './components/attachmentRevision/AttachmentRevisionList';
import AttachmentRevisionEdit from './components/attachmentRevision/AttachmentRevisionEdit';
import MocTypeList from './components/mocType/MocTypeList';
import MocTypeCreate from './components/mocType/MocTypeCreate';
import MocTypeEdit from './components/mocType/MocTypeEdit';
import MocTypeShow from './components/mocType/MocTypeShow';
import PunchCreate from './components/punch/PunchCreate';
import PunchEdit from './components/punch/PunchEdit';
import PunchList from './components/punch/PunchList';
import PunchShow from './components/punch/PunchShow';
import PreservationSeriesShow from './components/preservationSeries/PreservationSeriesShow';
import PreservationSeriesList from './components/preservationSeries/PreservationSeriesList';
import PreservationItemActivityList from './components/preservationItemActivity/PreservationItemActivityList';
import PreservationItemActivityEdit from './components/preservationItemActivity/PreservationItemActivityEdit';
import PreservationItemActivityShow from './components/preservationItemActivity/PreservationItemActivityShow';
import BookSectionCreate from './components/bookSection/BookSectionCreate';
import BookSectionEdit from './components/bookSection/BookSectionEdit';
import BookSectionList from './components/bookSection/BookSectionList';
import BookSectionShow from './components/bookSection/BookSectionShow';
import MocList from './components/moc/MocList';
import MocCreate from './components/moc/MocCreate';
import MocEdit from './components/moc/MocEdit';
import MocShow from './components/moc/MocShow';
import CertTemplateList from './components/certTemplate/CertTemplateList';
import CertTemplateEdit from './components/certTemplate/CertTemplateEdit';
import CertTemplateCreate from './components/certTemplate/CertTemplateCreate';
import CertTemplateShow from './components/certTemplate/CertTemplateShow';
import CertGatingList from './components/certGating/CertGatingList';
import CertGatingEdit from './components/certGating/CertGatingEdit';
import CertGatingShow from './components/certGating/CertGatingShow';
import ComponentActivityCreate from './components/componentActivity/ComponentActivityCreate';
import ComponentActivityEdit from './components/componentActivity/ComponentActivityEdit';
import ComponentActivityList from './components/componentActivity/ComponentActivityList';
import ComponentActivityShow from './components/componentActivity/ComponentActivityShow';
import SystemActivityList from './components/systemActivity/SystemActivityList';
import SystemActivityCreate from './components/systemActivity/SystemActivityCreate';
import SystemActivityEdit from './components/systemActivity/SystemActivityEdit';
import SystemActivityShow from './components/systemActivity/SystemActivityShow';
import SubSystemActivityList from './components/subSystemActivity/SubSystemActivityList';
import SubSystemActivityCreate from './components/subSystemActivity/SubSystemActivityCreate';
import SubSystemActivityEdit from './components/subSystemActivity/SubSystemActivityEdit';
import SubSystemActivityShow from './components/subSystemActivity/SubSystemActivityShow';
import DrawingPrefixList from './components/drawingPrefix/DrawingPrefixList';
import DrawingPrefixShow from './components/drawingPrefix/DrawingPrefixShow';
import DrawingPrefixEdit from './components/drawingPrefix/DrawingPrefixEdit';
import DrawingPrefixCreate from './components/drawingPrefix/DrawingPrefixCreate';
import AssignITRList from './components/assignITR/AssignITRList';
import AssignITREdit from './components/assignITR/AssignITREdit';
import SmartTagITRReviewList from './components/smartTagITRReview/SmartTagITRReviewList';
import ConfigurationEdit from './components/configuration/ConfigurationEdit';
import ConfigurationList from './components/configuration/ConfigurationList';
import ConfigurationShow from './components/configuration/ConfigurationShow';
import SmartTagITRList from './components/smartTagITR/SmartTagITRList';
import { BookGeneration } from './components/bookGeneration/BookGeneration';
import PendingPunchList from './components/pendingPunch/PendingPunchList';
import PendingPunchShow from './components/pendingPunch/PendingPunchShow';
import PendingPunchEdit from './components/pendingPunch/PendingPunchEdit';
import { QRCodeGeneration } from './components/qrCodeGeneration/QRCodeGeneration';
import ReportList from './components/report/ReportList';
import ReportShow from './components/report/ReportShow';
import ReportEdit from './components/report/ReportEdit';
import ReportCreate from './components/report/ReportCreate';
import ReportView from './components/report/ReportView';
import AttachmentTypeList from './components/attachmentType/AttachmentTypeList';
import AttachmentTypeCreate from './components/attachmentType/AttachmentTypeCreate';
import AttachmentTypeEdit from './components/attachmentType/AttachmentTypeEdit';
import AttachmentTypeShow from './components/attachmentType/AttachmentTypeShow';
import PunchTemplateList from './components/punchTemplate/PunchTemplateList';
import PunchTemplateEdit from './components/punchTemplate/PunchTemplateEdit';
import PunchTemplateShow from './components/punchTemplate/PunchTemplateShow';
import CertDetailsList from './components/certDetails/CertDetailsList';
import CertDetailsShow from './components/certDetails/CertDetailsShow';
import CertDetailsEdit from './components/certDetails/CertDetailsEdit';
import SmartCertDetailsList from './components/smartCertDetails/SmartCertDetailsList';
import SubscriptionPortal from './components/subscriptionPortal/SubscriptionPortal';
import SubmitPunchCreate from './components/submitPunch/SubmitPunchCreate';
import SubmitPunchEdit from './components/submitPunch/SubmitPunchEdit';
import SubmitPunchList from './components/submitPunch/SubmitPunchList';
import SubmitPunchShow from './components/submitPunch/SubmitPunchShow';
import useDataProvider from './provider/dataProvider/useDataProvider';
import LucidDiagramList from './components/lucidDiagram/LucidDiagramList';
import LucidDiagramCreate from './components/lucidDiagram/LucidDiagramCreate';
import LucidDiagramEdit from './components/lucidDiagram/LucidDiagramEdit';
import LucidDiagramShow from './components/lucidDiagram/LucidDiagramShow';
import LucidAuthCallback from './components/lucidDiagram/components/LucidAuthCallback';
import SmartPreservationItemActivityList from './components/smartPreservationItemActivity/SmartPreservationItemActivityList';
import MaterialTypeCreate from './components/materialType/MaterialTypeCreate';
import MaterialTypeEdit from './components/materialType/MaterialTypeEdit';
import MaterialTypeList from './components/materialType/MaterialTypeList';
import MaterialTypeShow from './components/materialType/MaterialTypeShow';
import StorageCreate from './components/storage/StorageCreate';
import StorageEdit from './components/storage/StorageEdit';
import StorageList from './components/storage/StorageList';
import StorageShow from './components/storage/StorageShow';
import MaterialReleasedList from './components/material/materialReleased/MaterialReleasedList';
import MaterialReleasedEdit from './components/material/materialReleased/MaterialReleasedEdit';
import MaterialReleasedCreate from './components/material/materialReleased/MaterialReleasedCreate';
import MaterialReleasedShow from './components/material/materialReleased/MaterialReleasedShow';
import { QueryClient } from '@tanstack/react-query';
import MaterialShow from './components/material/materialPage/MaterialShow';
import MaterialList from './components/material/materialPage/MaterialList';
import MaterialEdit from './components/material/materialPage/MaterialEdit';
import MaterialCreate from './components/material/materialPage/MaterialCreate';
import PurchaseOrderRequestList from './components/material/purchaseOrder/PurchaseOrderRequestList';
import PurchaseOrderRequestCreate from './components/material/purchaseOrder/PurchaseOrderRequestCreate';
import PurchaseOrderRequestEdit from './components/material/purchaseOrder/PurchaseOrderRequestEdit';
import PurchaseOrderRequestShow from './components/material/purchaseOrder/PurchaseOrderRequestShow';

export const LOCAL_STORE_VERSION = '6';

const i18nProvider = polyglotI18nProvider(() => en, 'en', {
  allowMissing: true,
});

type Props = {
  queryClient: QueryClient;
};

const AppContent: React.FC<Props> = ({ queryClient }): ReactElement => {
  const dataProvider = useDataProvider();

  return (
    <AdminEnterprise
      title="Tracker Check"
      lightTheme={lightTheme}
      darkTheme={null}
      loginPage={EmptyLogin}
      queryClient={queryClient}
      authProvider={getAuthProvider()}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      store={localStorageStore(LOCAL_STORE_VERSION)}
      disableTelemetry={true}
      layout={AppLayout}
      dashboard={Dashboard}
    >
      <CustomRoutes>
        <Route
          key={CUSTOM_ROUTE_USER_PROFILE}
          path={CUSTOM_ROUTE_USER_PROFILE}
          element={<UserProfileEdit />}
        />
      </CustomRoutes>
      <Resource
        name={RESOURCE_COMPANY_SUBSCRIPTION}
        list={SubscriptionPortal}
      />
      <Resource
        name={RESOURCE_PROJECT}
        list={ProjectList}
        edit={ProjectEdit}
        create={ProjectCreate}
        recordRepresentation={renderProjectOption}
        show={ProjectShow}
      />
      <Resource
        name={RESOURCE_MODULE}
        list={ModuleList}
        edit={ModuleEdit}
        create={ModuleCreate}
        show={ModuleShow}
      />
      <Resource
        name={RESOURCE_LOCATION}
        list={LocationList}
        edit={LocationEdit}
        create={LocationCreate}
        recordRepresentation={renderLocationOption}
        show={LocationShow}
      />
      <Resource
        name={RESOURCE_DISCIPLINE}
        list={DisciplineList}
        edit={DisciplineEdit}
        create={DisciplineCreate}
        recordRepresentation={renderDisciplineOption}
        show={DisciplineShow}
      />
      <Resource
        name={RESOURCE_DICTIONARY}
        list={DictionaryList}
        edit={DictionaryEdit}
        create={DictionaryCreate}
        show={DictionaryShow}
      />
      <Resource
        name={RESOURCE_SYSTEM}
        list={SystemList}
        create={SystemCreate}
        edit={SystemEdit}
        show={SystemShow}
      />
      <Resource
        name={RESOURCE_COMMENT}
        list={CommentList}
        show={CommentShow}
        create={CommentCreate}
        edit={CommentEdit}
      />
      <Resource
        name={RESOURCE_WORK_PACK}
        list={WorkPackList}
        edit={WorkPackEdit}
        create={WorkPackCreate}
        show={WorkPackShow}
        recordRepresentation={renderWorkPackOption}
      />
      <Resource
        name={RESOURCE_PUNCH_CATEGORY}
        list={PunchCategoryList}
        edit={PunchCategoryEdit}
        create={PunchCategoryCreate}
        show={PunchCategoryShow}
      />
      <Resource
        name={RESOURCE_PUNCH_PRIORITY}
        list={PunchPriorityList}
        edit={PunchPriorityEdit}
        create={PunchPriorityCreate}
        show={PunchPriorityShow}
      />
      <Resource
        name={RESOURCE_DIVISION}
        list={DivisionList}
        edit={DivisionEdit}
        create={DivisionCreate}
        recordRepresentation={renderDivisionOption}
        show={DivisionShow}
      />
      <Resource
        name={RESOURCE_STRUCTURE_REGION}
        list={StructureRegionList}
        edit={StructureRegionEdit}
        create={StructureRegionCreate}
        recordRepresentation={renderRegionOption}
        show={StructureRegionShow}
      />
      <Resource
        name={RESOURCE_SUBSYSTEM}
        list={SubSystemList}
        edit={SubSystemEdit}
        create={SubSystemCreate}
        show={SubSystemShow}
        recordRepresentation={renderSubSystemOption}
      />
      <Resource
        name={RESOURCE_STRUCTURE_ASSET_GROUP}
        list={StructureAssetGroupList}
        edit={StructureAssetGroupEdit}
        create={StructureAssetGroupCreate}
        show={StructureAssetGroupShow}
        recordRepresentation={renderAssetGroupOption}
      />
      <Resource
        name={RESOURCE_TEST_TYPE}
        list={TestTypeList}
        edit={TestTypeEdit}
        create={TestTypeCreate}
        show={TestTypeShow}
      />
      <Resource
        name={RESOURCE_STRUCTURE_ASSET}
        list={StructureAssetList}
        edit={StructureAssetEdit}
        create={StructureAssetCreate}
        recordRepresentation={renderAssetOption}
        show={StructureAssetShow}
      />
      <Resource
        name={RESOURCE_DRAWING_TYPE}
        list={DrawingTypeList}
        edit={DrawingTypeEdit}
        create={DrawingTypeCreate}
        show={DrawingTypeShow}
      />
      <Resource
        name={RESOURCE_GROUP}
        list={GroupList}
        edit={GroupEdit}
        create={GroupCreate}
        show={GroupShow}
      />
      <Resource
        name={RESOURCE_SECURITY_ROLE}
        list={SecurityRoleList}
        edit={SecurityRoleEdit}
        create={SecurityRoleCreate}
        show={SecurityRoleShow}
      />
      <Resource name={RESOURCE_AUDIT} list={AuditList} />
      <Resource name={RESOURCE_AUDIT_TABLE_NAME} />
      <Resource
        name={RESOURCE_SECURITY_GROUP}
        list={SecurityGroupList}
        edit={SecurityGroupEdit}
        create={SecurityGroupCreate}
        show={SecurityGroupShow}
      />
      <Resource
        name={RESOURCE_SECURITY_ROLE_PERMISSION}
        list={SecurityRolePermissionList}
        edit={SecurityRolePermissionEdit}
        create={SecurityRolePermissionCreate}
        show={SecurityRolePermissionShow}
      />
      <Resource
        name={RESOURCE_POSITION}
        list={positionList}
        edit={PositionEdit}
        create={positionCreate}
        show={PositionShow}
      />
      <Resource name={RESOURCE_ATTACHMENT} list={AdminAttachmentList} />
      <Resource
        name={RESOURCE_ATTACHMENT_TYPE}
        list={AttachmentTypeList}
        edit={AttachmentTypeEdit}
        create={AttachmentTypeCreate}
        show={AttachmentTypeShow}
      />
      <Resource
        name={RESOURCE_ATTACHMENT_REVISION}
        list={AttachmentRevisionList}
        edit={AttachmentRevisionEdit}
      />
      <Resource
        name={RESOURCE_ACTIVITY_TYPE}
        list={ActivityTypeList}
        edit={ActivityTypeEdit}
        create={ActivityTypeCreate}
        show={ActivityTypeShow}
      />
      <Resource
        name={RESOURCE_COMPONENT_ACTIVITY}
        list={ComponentActivityList}
        edit={ComponentActivityEdit}
        create={ComponentActivityCreate}
        show={ComponentActivityShow}
      />
      <Resource
        name={RESOURCE_SYSTEM_ACTIVITY}
        list={SystemActivityList}
        edit={SystemActivityEdit}
        create={SystemActivityCreate}
        show={SystemActivityShow}
      />
      <Resource
        name={RESOURCE_SUBSYSTEM_ACTIVITY}
        list={SubSystemActivityList}
        edit={SubSystemActivityEdit}
        create={SubSystemActivityCreate}
        show={SubSystemActivityShow}
      />
      <Resource
        name={RESOURCE_DRAWING_DISCIPLINE}
        list={DrawingDisciplineList}
        edit={DrawingDisciplineEdit}
        create={DrawingDisciplineCreate}
        show={DrawingDisciplineShow}
      />
      <Resource
        name={RESOURCE_VENDOR}
        list={VendorList}
        edit={VendorEdit}
        create={VendorCreate}
        show={VendorShow}
      />
      <Resource
        name={RESOURCE_ACTIVITY_PHASE}
        list={ActivityPhaseList}
        edit={ActivityPhaseEdit}
        create={ActivityPhaseCreate}
        show={ActivityPhaseShow}
      />
      <Resource
        name={RESOURCE_EQUIPMENT_TYPE}
        list={EquipmentTypeList}
        edit={EquipmentTypeEdit}
        create={EquipmentTypeCreate}
        show={EquipmentTypeShow}
      />
      <Resource
        name={RESOURCE_USER}
        list={UserList}
        edit={UserEdit}
        create={UserCreate}
        show={UserShow}
      />
      <Resource
        name={RESOURCE_DISCIPLINE_TEST}
        create={DisciplineTestCreate}
        list={DisciplineTestList}
        edit={DisciplineTestEdit}
        show={DisciplineTestShow}
      />
      <Resource
        name={RESOURCE_USER_GROUP}
        create={UserGroupCreate}
        edit={UserGroupEdit}
        list={UserGroupList}
        show={UserGroupShow}
      />
      <Resource
        name={RESOURCE_COMPONENT}
        create={ComponentCreate}
        edit={ComponentEdit}
        list={ComponentList}
        show={ComponentShow}
        recordRepresentation={renderComponentOption}
      />
      <Resource
        name={RESOURCE_COMPONENT_TEST}
        create={ComponentTestCreate}
        edit={ComponentTestEdit}
        list={ComponentTestList}
        show={ComponentTestShow}
      />
      <Resource
        name={RESOURCE_TEST}
        create={TestCreate}
        edit={TestEdit}
        list={TestList}
        show={TestShow}
        recordRepresentation={renderTestOption}
      />
      <Resource
        name={RESOURCE_DRAWING}
        create={DrawingCreate}
        list={DrawingList}
        edit={DrawingEdit}
        show={DrawingShow}
      />
      <Resource
        name={RESOURCE_DRAWING_PREFIX}
        list={DrawingPrefixList}
        show={DrawingPrefixShow}
        edit={DrawingPrefixEdit}
        create={DrawingPrefixCreate}
      />
      <Resource name={RESOURCE_IMPORT} list={ImportList} show={ImportShow} />
      <Resource
        name={RESOURCE_CONTACT}
        list={ContactList}
        edit={ContactEdit}
        create={ContactCreate}
        show={ContactShow}
      />
      <Resource
        name={RESOURCE_BOOK}
        list={BookList}
        create={BookCreate}
        edit={BookEdit}
        show={BookShow}
      />
      <Resource name={RESOURCE_PRINTED_BOOK} list={PrintedBooksList} />
      <CustomRoutes>
        <Route
          path={`${RESOURCE_BOOK}/:bookId/${RESOURCE_BOOK_SECTION}/*`}
          element={<BookSectionList />}
        />
        <Route
          path={`${RESOURCE_BOOK}/:bookId/${RESOURCE_BOOK_SECTION}/create/*`}
          element={<BookSectionCreate />}
        />
        <Route
          path={`${RESOURCE_BOOK}/:bookId/${RESOURCE_BOOK_SECTION}/:id/*`}
          element={<BookSectionEdit syncWithLocation={true} />}
        />
        <Route
          path={`${RESOURCE_BOOK}/:bookId/${RESOURCE_BOOK_SECTION}/:id/show/*`}
          element={<BookSectionShow />}
        />
        <Route
          path={CUSTOM_ROUTE_BOOK_GENERATION}
          element={<BookGeneration />}
        />
        <Route path={RESOURCE_ASSIGN_ITR} element={<AssignITRList />} />
        <Route
          path={`${RESOURCE_ASSIGN_ITR}/edit`}
          element={<AssignITREdit syncWithLocation={false} />}
        />
        <Route
          path={CUSTOM_ROUTE_QR_CODE_GENERATION}
          element={<QRCodeGeneration />}
        />
      </CustomRoutes>
      <Resource
        name={RESOURCE_PRESERVATION_ITEM}
        create={PreservationItemCreate}
        edit={PreservationItemEdit}
        list={PreservationItemList}
        show={PreservationItemShow}
      />
      <Resource
        name={RESOURCE_MOC}
        list={MocList}
        create={MocCreate}
        edit={MocEdit}
        show={MocShow}
      />
      <Resource
        name={RESOURCE_MOC_TYPE}
        list={MocTypeList}
        create={MocTypeCreate}
        edit={MocTypeEdit}
        show={MocTypeShow}
      />
      <Resource
        name={RESOURCE_PUNCH}
        create={PunchCreate}
        edit={PunchEdit}
        list={PunchList}
        show={PunchShow}
      />
      <Resource
        name={RESOURCE_PRESERVATION_SERIES}
        list={PreservationSeriesList}
        show={PreservationSeriesShow}
        options={{ label: 'Preservation-series' }}
      />
      <Resource
        name={RESOURCE_PRESERVATION_ITEM_ACTIVITY}
        edit={PreservationItemActivityEdit}
        list={PreservationItemActivityList}
        show={PreservationItemActivityShow}
      />
      <Resource
        name={RESOURCE_CERT_TEMPLATE}
        create={CertTemplateCreate}
        edit={CertTemplateEdit}
        list={CertTemplateList}
        show={CertTemplateShow}
      />
      <Resource
        name={RESOURCE_CERT_GATING}
        edit={CertGatingEdit}
        list={CertGatingList}
        show={CertGatingShow}
      />
      <Resource
        name={RESOURCE_CERT_DETAIL}
        edit={CertDetailsEdit}
        list={CertDetailsList}
        show={CertDetailsShow}
      />
      <Resource name={RESOURCE_SMART_CERT_DETAIL} list={SmartCertDetailsList} />
      <Resource
        name={RESOURCE_SMART_TAG_ITR_REVIEW}
        list={SmartTagITRReviewList}
      />
      <Resource
        name={RESOURCE_CONFIGURATION}
        edit={ConfigurationEdit}
        list={ConfigurationList}
        show={ConfigurationShow}
      />
      <Resource name={RESOURCE_SMART_TAG_ITR} list={SmartTagITRList} />
      <Resource
        name={RESOURCE_QR_SCANNER}
        list={QrScannerList}
        edit={QrScannerEdit}
        show={QrScannerShow}
      />
      <Resource
        name={RESOURCE_SUBMIT_PUNCH}
        create={SubmitPunchCreate}
        list={SubmitPunchList}
        edit={SubmitPunchEdit}
        show={SubmitPunchShow}
      />
      <Resource
        name={RESOURCE_PENDING_PUNCH}
        list={PendingPunchList}
        edit={PendingPunchEdit}
        show={PendingPunchShow}
      />
      <Resource
        name={RESOURCE_REPORT}
        list={ReportList}
        edit={ReportEdit}
        show={ReportShow}
        create={ReportCreate}
      />
      <Resource
        name={RESOURCE_PUNCH_TEMPLATE}
        list={PunchTemplateList}
        edit={PunchTemplateEdit}
        show={PunchTemplateShow}
      />
      <Resource
        name={RESOURCE_MATERIAL_RELEASED}
        list={MaterialReleasedList}
        create={MaterialReleasedCreate}
        edit={MaterialReleasedEdit}
        show={MaterialReleasedShow}
      />
      <CustomRoutes>
        <Route
          path={`${RESOURCE_REPORT}/:reportId/${RESOURCE_REPORT_VIEW}/:recordId`}
          element={<ReportView />}
        />
      </CustomRoutes>
      <Resource
        name={RESOURCE_LUCID_DIAGRAM}
        list={LucidDiagramList}
        create={LucidDiagramCreate}
        edit={LucidDiagramEdit}
        show={LucidDiagramShow}
      />
      <Resource
        name={RESOURCE_MATERIAL}
        list={MaterialList}
        create={MaterialCreate}
        edit={MaterialEdit}
        show={MaterialShow}
      />
      <CustomRoutes>
        <Route path="lucid-auth-callback" element={<LucidAuthCallback />} />
      </CustomRoutes>
      <Resource
        name={RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}
        list={SmartPreservationItemActivityList}
      />
      <Resource
        name={RESOURCE_MATERIAL_TYPE}
        list={MaterialTypeList}
        edit={MaterialTypeEdit}
        create={MaterialTypeCreate}
        show={MaterialTypeShow}
      />
      <Resource
        name={RESOURCE_STORAGE}
        list={StorageList}
        create={StorageCreate}
        edit={StorageEdit}
        show={StorageShow}
      />
      <Resource
        name={RESOURCE_MATERIAL_PURCHASE_ORDER}
        list={PurchaseOrderRequestList}
        create={PurchaseOrderRequestCreate}
        edit={PurchaseOrderRequestEdit}
        show={PurchaseOrderRequestShow}
      />
    </AdminEnterprise>
  );
};

export default AppContent;
