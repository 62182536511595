const sideFilterStyles = {
  sideFiltersCard: {
    order: -1,
    mr: 1,
    mt: 1.25,
    mb: 6.5,
    width: 255,
    minWidth: 255,
  },
  sideFiltersCardContainer: {
    '& form.RaFilter-form': {
      display: 'block',
      '& > div': { display: 'block' },
    },
  },
  sideFiltersSelectInput: { display: 'flex', flexWrap: 'nowrap' },
  savedQueryBox: {
    '&.MuiBox-root': {
      width: '100%',
      pb: '10px',
      borderBottom: '2px solid grey',
    },
  },
  sideEditCard: {
    order: 1,
    marginLeft: '10px',
    width: '40%',

    '& div#form_tab form > div:first-of-type': {
      margin: '0 -15px -30px -15px',
      '& .MuiBox-root': {
        width: '100%',
      },
    },
    '& div#attachment_tab,\
     & div#previous_attachment_tab,\
     & div#pending_punch_common_tab': {
      overflowX: 'auto',
      padding: '16px',
    },
  },
  sideFiltersBoxMarginBottom: {
    '> *:not(:last-child)': {
      marginBottom: '24px',
    },
  },
};

export default sideFilterStyles;
