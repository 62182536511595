import React, { ReactElement, useMemo } from 'react';
import AutocompleteReferenceInput from '../../common/AutocompleteReferenceInput';
import commonStyles from '../../common/commonStyles';
import { RESOURCE_PROJECT } from '../../constants';
import { getProjectValidator } from '../helpers';
import { required } from '../../../utils/UtilityFunctions';
import { getContext, UserContext } from '../../../provider/userContext';

interface ProjectInputProps {
  label: string;
  source: string;
  isRequired?: boolean;
  readOnly?: boolean;
}

const ProjectInput: React.FC<ProjectInputProps> = ({
  label,
  source,
  isRequired,
  readOnly,
}): ReactElement => {
  const ctx: UserContext = getContext();
  const requestParams = useMemo(() => {
    return {
      filter: { divisionId: ctx.defaultDivisionId },
      sort: { field: 'id', order: 'ASC' as const },
    };
  }, [ctx.defaultDivisionId]);

  return (
    <AutocompleteReferenceInput
      resource={RESOURCE_PROJECT}
      requestParams={requestParams}
      source={source}
      label={label}
      name="project_inc"
      optionText="project"
      validate={isRequired ? [required(), getProjectValidator()] : []}
      sx={commonStyles.flexBoxSelectItem}
      readOnly={readOnly}
      getOptionDisabled={(option) => !option.isActive}
      includeFields={['id', 'project', 'isActive']}
      additionalParams={{ skipListAdditionalData: true }}
    />
  );
};

export default ProjectInput;
