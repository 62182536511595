import React, { ReactElement } from 'react';
import { NumberField, TextField, useStore, DateField } from 'react-admin';
import ComponentSidebarFilters from './components/ComponentSidebarFilters';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import ComponentDrawingField from './components/ComponentDrawingField';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import { getSideFiltersKey } from '../common/helpers/storeKeys';
import BulkActionButtons from './components/BulkActionButtons';
import CustomBooleanField from '../common/CustomBooleanField';
import EquipmentTypeField from '../common/EquipmentTypeField';
import DisciplineField from '../common/DisciplineField';
import { COMPONENT_EXPORT_BUTTONS } from './constants';
import SubsystemField from '../common/SubsystemField';
import ColumnActions from '../common/ColumnActions';
import WorkPackField from '../common/WorkPackField';
import LocationField from '../common/LocationField';
import ListWithTitle from '../common/ListWithTitle';
import { RESOURCE_COMPONENT } from '../constants';
import VendorField from '../common/VendorField';
import ListActions from '../common/ListActions';
import ModuleField from '../common/ModuleField';
import GroupField from '../common/GroupField';
import ComponentShow from './ComponentShow';
import ComponentEdit from './ComponentEdit';

const ComponentList: React.FC = (): ReactElement => {
  const sideFilter = getSideFiltersKey(RESOURCE_COMPONENT);
  const [showSideFilter] = useStore(sideFilter, false);
  const defaultFilter = useDefaultFilter('project');

  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={
        <ListActions
          sideFilter={sideFilter}
          defaultFilter={defaultFilter}
          multipleExportButtonsInfo={COMPONENT_EXPORT_BUTTONS}
        />
      }
      aside={
        showSideFilter ? (
          <ComponentSidebarFilters defaultFilter={defaultFilter} />
        ) : null
      }
    >
      <DatagridConfigurableRbac
        ShowComponent={<ComponentShow withoutActions />}
        EditComponent={<ComponentEdit syncWithLocation={false} />}
        bulkActionButtons={<BulkActionButtons />}
        defaultVisibleColumns={[
          'id',
          'tag',
          'tagDescription',
          'subsystemId',
          'disciplineId',
          'equipTypeId',
          'componentDrawings',
          'updatedAt',
        ]}
        sx={{
          '& .column-tag': { maxWidth: '180px' },
          '& .column-tagDescription': {
            maxWidth: '360px',
          },
        }}
      >
        <TextField source="id" label="ID" />
        <SubsystemField source="subsystemId" />
        <TextField
          source="subsystem.description"
          label="Subsystem Description"
        />
        <DisciplineField source="disciplineId" />
        <TextField source="tag" label="Tag" />
        <TextField source="tagDescription" label="Tag Description" />
        <CustomBooleanField
          source="skipItrAutomation"
          label="Skip ITR Automation"
        />
        <CustomBooleanField source="isMaintenance" label="Maintenance" />
        <GroupField source="groupId" />
        <ModuleField source="moduleId" />
        <WorkPackField source="workPackId" />
        <LocationField source="locationId" />
        <EquipmentTypeField source="equipTypeId" />
        <ComponentDrawingField
          source="componentDrawings"
          label="Drawings"
          sortable={false}
        />
        <CustomBooleanField source="tagScoped" label="Tag Scoped" />
        <CustomBooleanField source="tagVerified" label="Tag Verified" />
        <TextField source="parentTag" label="Parent Tag" />
        <TextField source="dataSheet" label="Data Sheet" />
        <TextField source="manufacturer" label="Manufacturer" />
        <TextField source="model" label="Model" />
        <TextField source="serial" label="Serial" />
        <TextField source="batch" label="Batch" />
        <TextField source="purchaseOrder" label="Purchase Order" />
        <VendorField source="vendorId" />
        <DateField source="deliveryDate" label="Delivery Date" />
        <TextField source="hardSoft" label="Hard Soft" />
        <TextField source="storageLocation" label="Storage Location" />
        <TextField source="tagCategory" label="Tag Category" />
        <NumberField source="verifiedBy" label="Verified By" />
        <DateField source="verifiedDate" label="Verified Date" />
        <CustomBooleanField source="verified" label="Verified" />
        <TextField source="cableFrom" label="Cable From" />
        <TextField source="cableTo" label="Cable To" />
        <TextField source="engList" label="EngList" />
        <NumberField source="latitude" label="Latitude" />
        <NumberField source="longitude" label="Longitude" />
        <NumberField source="ifcQty" label="IFC Quantity" />
        <TextField source="listSource" label="List Source" />
        <TextField source="remarks" label="Remarks" />
        <NumberField source="trackByQty" label="Track By Quantity" />
        <CustomBooleanField
          source="hasElectrical"
          label="Has Electrical Record"
        />
        <CustomBooleanField source="hasPiping" label="Has Piping Record" />
        <CustomBooleanField
          source="hasMechanical"
          label="Has Mechanical Record"
        />
        <CustomBooleanField
          source="hasInstrumentation"
          label="Has Instrumentation Record"
        />
        {renderMetaDataFields()}
        <ColumnActions label="Actions" />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default ComponentList;
