import React, { ReactElement } from 'react';
import { ArrayInput, SimpleFormIterator } from 'react-admin';
import { getContext, UserContext } from '../../../provider/userContext';
import { required } from '../../../utils/UtilityFunctions';
import ProjectsInputField from './ProjectsInputField';

const ProjectsInputArrayField: React.FC = (): ReactElement => {
  const ctx: UserContext = getContext();

  return (
    <ArrayInput
      label="Projects"
      source={`companies.${ctx?.company}.projects`}
      name={`companies.${ctx?.company}.projects`}
      validate={[required()]}
    >
      <SimpleFormIterator inline>
        <ProjectsInputField />
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export default ProjectsInputArrayField;
