import React, { ReactElement, useCallback } from 'react';
import { TextField, DateField, useStore } from 'react-admin';
import ComponentTestSidebarFilters from './components/ComponentTestSidebarFilters';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import useUpdateSelectedIds from '../common/hooks/useUpdateSelectedIds';
import MobileTestStatusField from './components/MobileTestStatusField';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import DisciplineTestField from '../common/DisciplineTestField';
import { getSideFiltersKey } from '../common/helpers/storeKeys';
import BulkActionButtons from './components/BulkActionButtons';
import CustomBooleanField from '../common/CustomBooleanField';
import EquipmentTypeField from '../common/EquipmentTypeField';
import AssignedField from '../common/Assigned/AssignedField';
import DisciplineField from '../common/DisciplineField';
import SubsystemField from '../common/SubsystemField';
import ComponentField from '../common/ComponentField';
import ColumnActions from '../common/ColumnActions';
import LocationField from '../common/LocationField';
import ComponentTestEdit from './ComponentTestEdit';
import ComponentTestShow from './ComponentTestShow';
import WorkPackField from '../common/WorkPackField';
import TestTypeField from '../common/TestTypeField';
import ListWithTitle from '../common/ListWithTitle';
import VendorField from '../common/VendorField';
import SelectField from '../common/SelectField';
import ListActions from '../common/ListActions';
import SystemField from '../common/SystemField';
import TestField from '../common/TestField';
import { statuses } from './constants';
import {
  RESOURCE_COMPONENT_TEST,
  RESOURCE_SMART_TAG_ITR,
  RESOURCE_SMART_TAG_ITR_REVIEW,
} from '../constants';

const ComponentTestList: React.FC = (): ReactElement => {
  const sideFilter = getSideFiltersKey(RESOURCE_COMPONENT_TEST);
  const [showSideFilter] = useStore(sideFilter, false);
  const defaultFilter = useDefaultFilter('project', { isDeleted: false });
  const updateSmartTagItrSelectedIds = useUpdateSelectedIds(
    RESOURCE_SMART_TAG_ITR
  );
  const updateSmartTagItrReviewSelectedIds = useUpdateSelectedIds(
    RESOURCE_SMART_TAG_ITR_REVIEW
  );

  const onDelete = useCallback(
    (record) => {
      updateSmartTagItrSelectedIds(record.id);
      updateSmartTagItrReviewSelectedIds(record.id);
    },
    [updateSmartTagItrSelectedIds, updateSmartTagItrReviewSelectedIds]
  );

  return (
    <ListWithTitle
      filters={<FreeTextSearchFilter />}
      filterDefaultValues={defaultFilter}
      actions={
        <ListActions sideFilter={sideFilter} defaultFilter={defaultFilter} />
      }
      sort={{ field: 'id', order: 'ASC' }}
      aside={
        showSideFilter ? (
          <ComponentTestSidebarFilters defaultFilter={defaultFilter} />
        ) : null
      }
      queryOptions={{ meta: { excludeFields: ['formData'] } }}
    >
      <DatagridConfigurableRbac
        ShowComponent={<ComponentTestShow withoutActions />}
        EditComponent={<ComponentTestEdit syncWithLocation={false} />}
        bulkActionButtons={<BulkActionButtons />}
        defaultVisibleColumns={[
          'id',
          'componentId',
          'testId',
          'locationId',
          'isVerified',
          'status',
          'completedBy',
          'completedDateTime',
          'isMobile',
          'assignedEmail',
          'mobileTestStatus',
        ]}
      >
        <TextField source="id" label="ID" />
        <ComponentField source="componentId" />
        <TextField
          source="component.tagDescription"
          label="Tag Description"
          sortBy="component.tag_description"
        />
        <TestField
          source="testId"
          textSource="test.testFormNo"
          sortBy="test.test_form_no"
        />
        <TextField
          source="test.testName"
          label="Test Name"
          sortBy="test.test_name"
        />
        <LocationField source="locationId" />
        <EquipmentTypeField source="equipTypeId" />
        <CustomBooleanField source="isVerified" label="Verified" />
        <SelectField source="status" label="Status" choices={statuses} />
        <TextField source="completedBy" label="Completed By" />
        <DateField source="completedDateTime" label="Completed Test Date" />
        <TextField source="itrTotalSignatures" label="ITR Total Signatures" />
        <TextField source="itrSigned" label="ITR Signed" />
        <TextField source="itrSignedBy" label="ITR Signed By" />
        <CustomBooleanField source="retest" label="Retest" />
        <TextField source="retestReason" label="Retest Reason" />
        <TextField source="reviewComments" label="Review Comments" />
        <CustomBooleanField source="isPrinted" label="Printed" />
        <DateField source="printedDate" label="Printed Date" />
        <TextField source="printedBy" label="Printed By" />
        <CustomBooleanField source="isMobile" label="Mobile" />
        <AssignedField label="Responsible" sortable={false} />
        <VendorField
          source="responsibleVendorId"
          textSource="responsibleVendor.vendor"
          label="Responsible Vendor"
          sortBy="`responsibleVendor.vendor`"
        />
        <MobileTestStatusField
          source="mobileTestStatus"
          label="Mobile Test Status"
        />
        <DisciplineTestField
          label="Test Discipline"
          source="test.disciplineTestId"
          textSource="test.disciplineTest.disciplineTest"
          sortBy="`test.disciplineTest.disciplineTest`"
        />
        <DisciplineField
          label="Tag Discipline"
          source="component.disciplineId"
          textSource="component.discipline.discipline"
          sortBy="`component.discipline.discipline`"
        />
        <WorkPackField
          label="Work Pack"
          source="component.workPackId"
          textSource="component.workPack.workPack"
          sortBy="`component.workPack.workPack`"
        />
        <TestTypeField
          label="Test Type"
          source="test.testTypeId"
          textSource="test.testType.testType"
          sortBy="`test.testType.testType`"
        />
        <SubsystemField
          source="component.subsystemId"
          textSource="component.subsystem.subsystem"
          sortBy="`component.subsystem.subsystem`"
        />
        <TextField
          source="component.subsystem.description"
          label="SubSystem Description"
          sortBy="`component.subsystem.description`"
        />
        <SystemField
          source="component.subsystem.systemId"
          textSource="component.subsystem.system.systemName"
          sortBy="`component.subsystem.system.systemName`"
          label="System"
        />
        <TextField
          source="component.subsystem.system.description"
          label="System Description"
          sortBy="`component.subsystem.system.description`"
        />
        {renderMetaDataFields()}
        <ColumnActions label="Actions" onDelete={onDelete} />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default ComponentTestList;
